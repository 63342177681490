.react-responsive-carousel .carousel .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Set video dimensions */
.react-responsive-carousel .carousel .slide video {
  width: 100vw;
  height: 100vh;
}
.carousel-root{
  height: calc(100vh - 200px);
}

.carousel.carousel-slider {
  height: calc(100vh - 200px);
}

/* Style the legend */
.react-responsive-carousel .carousel .slide .legend {
  color: white;
  font-size: 18px;
  margin-top: 10px;
}

.mobile-video {
  width: 320px; /* Adjust the width for mobile */
  height: 180px; /* Adjust the height for mobile */
}

.desktop-video {
  width: 1920px; /* Adjust the width for desktop */
  height: 1080px; /* Adjust the height for desktop */
}

/* Style autoplay and navigation buttons */
.react-responsive-carousel .thumbs-wrapper {
  display: none; /* Hide thumbnail navigation */
}
.video-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  padding-bottom: 56.25%; /* Maintain a 16:9 aspect ratio for the videos */
}

.video-wrapper video {
  width: 100vw;
  height: calc(100vh - 100px);
  object-fit: cover;
}

.carousel-status-indicators {
  display: none;
}
p.carousel-status {
  display: none;
}




@media (max-width: 768px) {

  .react-responsive-carousel .carousel .slide video {
    width: 100%;
    height: 40vh;
    object-fit: contain; /* Resize the video to fit within the container */
  }

  /* Move the video text under the video */
  .react-responsive-carousel .carousel .slide .legend {
    text-align: center;
    margin-top: 5px; /* Adjust the margin as needed */
  }

  /* Optional: Center the video horizontally on mobile */
  .video-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-wrapper video {
  width: 100vw;
  transform: translate(0,100px);
  top: 100px;
  height: calc(100vh - 100px);
  object-fit: cover;
}

 

}

/* For larger screens, maintain the original styles */
.react-responsive-carousel .carousel .slide video {
  max-width: 100%;
  max-height: 100%;
}

/* Style the legend */
.react-responsive-carousel .carousel .slide .legend {
  color: white;
  font-size: 18px;
  margin-top: 10px;
}

/* Style autoplay and navigation buttons */
.react-responsive-carousel .thumbs-wrapper {
  display: none; /* Hide thumbnail navigation */
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Maintain a 16:9 aspect ratio for the videos */
  overflow: hidden;
}
