/* ContentPage.css */

.content-page {
  max-width: 100vw;
  margin: 1vh 2vw;
  padding: 20px;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row-reverse;;
}

.content-page h2 {
  display: flex;
  font-size: 3rem;
  text-align: left;
  margin-bottom: 10px;
  color: #1c3583;
  font-family: "Poppins"

}

.contentmain-img {
  max-width: 640px;
  margin-bottom: 20px;
  align-items: center;
  object-fit: contain;
  max-height: 900px;
}
.content-text {
  display: flex;
  flex-direction: column;
  
}

.Description {
  white-space: pre-line;
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Poppins";
  text-align: left;
  width: 43vw;
}

.Names {
  font-size: 1rem;
  margin-top: auto;
  font-family: "Poppins"
}

.collabcard img{
  width: 90px;
  height: 70px;
  border-radius: 50%;
  object-fit:cover;

}

.collabcard{
  display: flex;
  padding: 20px 0 20px 0;
  justify-content: space-between;
  max-width: 63%;
  gap: 15px;
}

.collabcard-content{
  display: flex;
  flex-direction: row;
}

.collabcard-content a {
  display: block;
  margin-bottom: 5px;
  text-decoration: none;
  color: #0097B2;
  font-weight: bold;
  font-family: "Poppins";
  padding-left: 15px;
  font-size: 1vw;
  align-self: center;
}

@media (max-width: 767px) {


  .content-page {
  max-width: 100vw;
  margin: 1vh 2vw;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.content-page h2 {
  display: flex;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 10px;
  color: #1c3583;
  font-family: "Poppins"

}

.contentmain-img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  margin-top:-1vh;
  align-items: center;

}
.content-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-page Description {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  font-family: "Poppins"
}

.Names {
  font-size: 1rem;
  margin-top: auto;
  font-family: "Poppins"
}

.collabcard{
  flex-direction: column;
}

.collabcard-content {
  margin:10px;
}

.ccollabcard-content a {
  display: block;
  margin-bottom: 5px;
  text-decoration: none;
  color: #0097B2;
  font-weight: bold;
  font-family: "Poppins";
  font-size: 3vw;
}

.Description{
  text-align: center;
  width:100%
}

  
}