.scroll-button {
  padding: 12px 26px;
  font-size: 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #CC5500;
  border: 1px solid #cc5500;
  color: white;
}

/* Hover effect */
.scroll-button:hover {
  background: #fff;
  color: #CC5500;
  transition: all 0.3s ease-out;
}