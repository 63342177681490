.main-page{
  width: 60vw;
  min-height: 60vh;
  margin: 4vh 4vw;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-self: center;
  }
  
  .associated-projects {
    width: 65%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
    overflow-y: auto;
  }
  
  .projects-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }

  .associated-projects h2{
  display: flex;
  font-size: 1rem;
  margin-bottom: 10px;
  color: #1c3583;
  font-family: "Poppins";
  font-weight: 300;
  }
  
  .associated-projects h3{
  display: flex;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #1c3583;
  font-family: "Poppins";
  }

  .project-card {
    display: flex;
    background-color: #1c3583;
    color: white;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px;
    align-items: center;
    transition: background-color 0.3s;
    cursor: pointer;
    border: 1px solid #ddd;
  }

  .project-card:hover {
    background-color: white;
  }
  
  .project-card:hover .project-link,
  .project-link:hover {
    color: #1c3583;;
  }
  
  .project-link:hover {
    text-decoration: underline;
  }

  .project-image {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    margin-right: 10px;
    object-fit: cover;
  }
  
  
  .main-page .projects-content li a {
  text-decoration: none;
  color: white;
  font-family: "Poppins"
  }
  
  .projects-contentlist li a:hover{
  text-decoration: none;
  color: #ebcece;
  font-family: "Poppins"
  }
  
  .peopledetailbox {
  border-radius: 15px;
  background: #1c3583;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  justify-content: center;
  color: white;
  }
  
  .peopledetailbox img{
  display: flex;
  width: 7vw;           
  height: 7vw; 
  margin-bottom: 20px;
  align-items: center;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  border-color: white;
  border-style: solid;
  }
  
  .peopledetailbox h2{
  text-align: center;
  font-family: "Poppins";
  font-size: 18px;
  }
  
  .peopledetailbox p{
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  }
  
  .social-links {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .social-links a {
    font-size: 24px;
    color: #fefefe;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .social-links a:hover {
    color: #ebcece;
  }
  
  .icon {
    width: 24px;
    height: 24px;
  }

  .social-links a:hover {
    color: #ebcece;
  }

  .associated-projects p a {
    text-decoration: none;
    color: #3a6bff;
    transition: color 0.3s;
  }

  .associated-projects p a:hover {
    color: #ff7f50;
    text-decoration: none;
  }

  @media (max-width: 767px) { 
  .main-page {
      width: auto;
      flex-direction: column;
      height: auto;
      gap: 0;
    }
  
    .peopledetailbox {
      width: 100%;
    }
  
    .peopledetailbox img {
      height: 23vw;
      width: 23vw;
    }
  
    .associated-projects h2 {
      font-size: 0.8rem;
    }
  
    .associated-projects h3 {
      transform: translate(0, 2vh);
    }
  
    .associated-projects {
      margin: 10px 0;
      width: 100%;
      padding: 10px;
      gap: 10px;
    }
  
    .projects-content {
      gap: 1vh;
      grid-template-columns: 1fr;
      padding-top: 10px;
    }
  
    .peopledetailbox p {
      font-size: 0.8rem;
    }
  
    .peopledetailbox h2 {
      font-size: 1rem;
    }
  
    .main-page .projects-content li a {
      font-size: 0.8rem;
    }
  
    .project-card {
      padding: 8px;
    }
  
    .project-image {
      width: 60px;
      height: 60px;
    }
  }