.audt-container {
  
  margin: 0 0;
  padding: 0;
  background: rgb(228,235,251);
background: -moz-linear-gradient(90deg, rgba(228,235,251,1) 35%, rgba(255,255,255,1) 81%);
background: -webkit-linear-gradient(90deg, rgba(228,235,251,1) 35%, rgba(255,255,255,1) 81%);
background: linear-gradient(90deg, rgba(228,235,251,1) 35%, rgba(255,255,255,1) 81%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e4ebfb",endColorstr="#ffffff",GradientType=1);
  border-radius: 15px;
  font-family: 'Poppins', Tahoma, Geneva, Verdana, sans-serif;
}


.quick-links {
  background-color: #f0f0f0;
  padding: 10px 0;
  text-align: center;
  margin-bottom: 0px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position:relative;
  font-family:'Poppins';
}
.toggle-button {
  display: none; /* Initially hidden on larger screens */
  cursor: pointer;
  /* Add more styles here as needed */
}


.links-container.visible {
  display: block;
}

.links-container.hidden {
  display: none;
}



.quick-links h3 {
  text-align: left;
  color: #333;
  margin: 0;
  padding-left: 15px; /* Adjust as needed */
  font-size: 20px; /* Adjust as needed */
  position: absolute; /* Positioning inside the quick-links bar */
  top: 10px; /* Adjust as needed */
  left: 0;
}

.quick-links a {
  color: #333;
  text-decoration: none;
  margin: 0 15px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.audt-section a{
  text-decoration:none;
  color: rgba(255, 17, 17, 0.947);
  font-weight: bold;
}

.audt-section a:hover{
  text-decoration:none;
  color: white;
}

.quick-links a:hover {
  color: #007bff;
}

.audt-section {
  margin-bottom: 40px;
  padding: 20px 50px 20px 50px;
  border-bottom:1px solid rgb(203, 203, 203);
  
}

.audt-section h2 {
  margin-bottom: 20px;
  color: #2a2a72;
  font-size: 2.5rem;
}

.audt-section p, .audt-section ul {
  color: #333;
  line-height: 1.6;
  font-size:1.3rem;
}

.audt-section ul {
  list-style: inside square;
}

.audt-section ul li {
  margin-bottom: 10px;
}

.header-section {
  position: relative;
  margin-bottom: 40px;
  max-height: 400px;
}

.header-img {
  position: relative;
  z-index: 0;
  height: 350px;
  object-fit: cover;
  width: 100vw;
}

.header-mask {
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5); /* Darkened background */
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align everything to the top */
  align-items: flex-start; /* Align text and logos to the left */
  padding-top: 40px; /* Adjust padding-top to position content correctly */
}

.header-title {
  color: white;
  font-size: 60px; ;
  margin-bottom: 20px;
  /* max-width: 900px; */
  margin-top: 20px;
}

.header-subtitle p {
  text-align: left;
  color: white;
  margin: 5px 0;
}

.logos-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  gap: 20px;
}

.org-logo {
  height: 80px;
  width: auto;
}

.org-logo.NSF {
  height: 80px;
}

.presentation-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
}

.presentation-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* Responsive Design */
@media (max-width: 768px) {

  .audt-section h2 {
    font-size: 20px;
  }

  .audt-section p, .audt-section ul {
    font-size: 16px;
}

.audt-section {
  font-size: 16px;
  padding: 20px;
  margin-bottom: 0px;
}

.header-title {
  font-size: 1.5rem;
}

.header-mask{
  padding:20px;
}

.logos-container {
  flex-direction: row;;
  align-items: center;
}

.org-logo {
  height: 50px;
  width: 90px;
}
  .quick-links a {
    display: block; /* Make each link block-level for easier touch */
    margin: 5px 0; /* Add margin between links for separation */
    padding: 8px 0; /* Increase padding for a larger touch area */
    font-size: 16px; /* Adjust font size for mobile readability */
  }

  .toggle-button {
    display: inline-block; /* Show button on smaller screens */
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
  }

.org-logo.NSF{
  width: 50px;
  height: 50px;
}

.schedule-table {
  width: 100%;
}

.schedule-row {
  align-items: flex-start;
}

.time-column,
.topic-column {
  width: 100%;
  font-size: 20px;
}

.schedule-title {
  font-size: 20px;
}

.schedule-subtitle {
  font-size: 18px;
}

.schedule-text {
  font-size: 16px;
}

.schedule-section{
  margin:0px;
  padding:20px;
}

}


@media (min-width: 769px) {
  .links-container {
    display: block !important; /* Force visibility in desktop view */
  }
  .quick-links{
    display:block;
  }
  .toggle-button {
    display: none; /* Hide the toggle button in desktop view */
  }
}