/* Publications.css */
.publications-container {
  padding: 20px;
}

.publications-list {
  list-style: none;
  padding: 0;
}

.publication-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  

}

.pub-name{
  text-decoration: none;

}