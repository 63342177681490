.header-banner{
  height: 200px;
}

.header-banner h1{
  margin-bottom: -23vh;
  z-index: 30;
  top: 0vh;
  margin-top: -20vh;
  margin-left: 40px;
  color: #ffffff;
  text-shadow: 5px 5px 0px #0097B2; /* Add a text shadow for better visibility */
  text-align: start;
  padding: 0px 0;
  font-size: 5rem;
  font-family: 'Poppins';
  position: relative;
}

/* .cardsgrid{
    background: #f7f7f7;
} */


.wall-container {
  position: relative;/* Make the container a positioning context for absolute positioning */
   width: 100%; /* Set the width to 100% to make it full width */
  height: 100%; /* Set the desired height for your video container */
  overflow: hidden;

}

.bg-video {
  position: relative;
  top: 0;
  left: 0;
  width: 100%; /* Set the width to 100% to match the container width */
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  object-position: center;
  margin-bottom: 10%;
}

.category-boxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin: 20px 0;
  padding: 50px;
}

.category-box {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.category-box:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3); 
}

.category-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 53, 131,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-box.active {
  border: 2px solid black;
}

.category-box h2 {
  color: white;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.button-style{
  margin-top: 20px;
  margin-left: 40px;
}


@media (max-width: 767px) {
  
  .cardsgrid h1{
    margin-bottom: 0;
    margin-left: 40px;
    text-align: start;
    padding: 0px 0;
    font-size: 2rem;
    font-family: 'Poppins';
    position: relative;
}

.header-banner h1{
  margin-bottom: 0;
  margin-left: 40px;
  text-align: start;
  padding: 0px 0;
  font-size: 2rem;
  font-family: 'Poppins';
  position: relative;
}


.wall-container {
  position: relative; /* Make the container a positioning context for absolute positioning */
  height: auto;
}

.bg-img {
  position: relative;
  width: 100vw;
  transform: translateY(0%);
  top: 0;
  left: 0;
  z-index: 0;
  height: 45vh;
  object-fit: cover;
  overflow: hidden;
}

.category-box {
  height: 200px;
}

.category-box h2 {
  font-size: 24px;
}

.category-boxes {
  grid-template-columns: repeat(1, 1fr);
  padding: 50px 20px 20px 20px;
}

.back-button {
  margin-top: 50px;
  margin-left: 20px;
}


}
