body, .card-container {
    margin: 0;
    padding: 0;
}

.card-container{
    width: 100vw;
    
    background-color: #fffefe;
    display: flex; /* Add this line to make the cards flex containers */
    flex-wrap: wrap;

}

.picture-container {
    
    height: 100%; /* Fill the height of the card */
    display: flex;
    width: 50%; /* Limit the max-width of the image */
    overflow: hidden;

}

.picture-container img {

    width: 100%; /* Limit the max-width of the image */
    object-fit: cover;

}

.card{
    width: 100vw;
    height: auto;
    
}

.card-content {
    display: flex;
    height: 100%;
    align-items: center;

}

.image-on-left {
    flex-direction: row;
    background-color: #ffffff
}

.image-on-right {
    flex-direction: row-reverse;
    background-color: #ffffff

    
}

.writeup {
  flex:1;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  justify-content: center;
  align-items: flex-start;
  margin: 60px;
  padding: 24px;
  color: #1c3583;
  width: 50%;
  font-size: 1.2rem;
  gap: 10px;
}



.cardtitle-text{
    text-decoration: none ;
    color: black;
    font-weight: bold;
    font-family: 'Poppins', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
    font-size: 3rem;
}

    .cardtitle-text:hover {
        color:#ff850b;
        transition-duration:0.2s;
        
    }

    
@media (max-width: 767px) {
    .card{
    width: 100vw;
    height: auto;
    
}
    .card-content {
        flex-direction: column; /* Stack the items in a column */
        display: grid;
        align-items: center;
        text-align: center;
        padding-top: 20px; /* Adjust spacing as needed */
    }

    .picture-container{
    height: 22vh;
    display: flex;
    width: 100vw; /* Limit the max-width of the image  */
    max-height: 50vh;
    object-fit: contain;
    overflow: hidden;

}

.picture-container img{
    
    display: flex;
    max-width: 100vw; /* Limit the max-width of the image  */
    max-height: 50vh;
    object-fit: contain;
    overflow: hidden;

}



    .writeup {
        width: 100%; /* Make the text take full width */
        margin-bottom: 20px; /* Adjust spacing as needed */
        color: #1c3583;
        font-size: 1.5rem;
        font-family: 'Poppins';
        margin:0px;
    }

    .cardtitle-text{
        font-size: 2rem; /* Adjust font size for mobile */

    }
    
}