/* Styling for the main container */
.meet-the-team {
  text-align: center;
  padding: 20px;
  background-attachment: fixed; /* Adjust the gradient colors as needed */
  background-image: url("/public/images/NetworkImage.png");
}

.meet-the-team h1{
  font-size: 3rem;
  text-align: center;
  color: black;
}

/* Styling for section headings */
.team-section h2 {
  font-size: 24px;
  margin-bottom: 0px;
  font-family: 'Poppins';
  
}

.member-content {
  display: flex;
  align-items: center;
}

.member-info p {
  text-align: left;
  margin: 0;
  font-size: 14px;
  color: black;
}

.member-info .name {
  font-size: 20px; 
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
}

.member-info .title {
  font-size: 16px;
  color: #0097B2;
  margin-bottom: 5px;
}

.member-info .projects .project-title {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Styling for team members */
.team-members {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px;
  padding: 20px;
  
}

.team-member {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center; /* Align the image and text horizontally */
  justify-content: flex-start;
}

.team-member a{
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team-member img {
  width: 150px; 
  height: 150px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
}

.projects {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.team-members-boss {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
}

.team-members-boss .team-member{
  width: 400px;
  margin-top: 10px;
  margin-right: 40px;
  margin-left:40px;
  padding: 10px;
  background-color: rgba(0,0,0,0.0);
  height:auto;

}

.team-members-boss .team-member img {
  width: 350px;
  height: 350px;
  object-fit: cover;
  
}

@media (max-width: 768px) {
  .team-members {
  display: flex;
  flex-wrap: wrap;
}

.team-member img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.member-info .name {
  font-size: 16px;
}

.member-info .title {
  font-size: 12px;
}

}