.intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  padding: 20px 20px 40px 20px;
  z-index: 2;
}

.sections-container {
  display: flex;
  width: 100%;
  z-index: 2;
}

.introduction {
  flex: 1.5 1;
  padding: 20px;
  z-index: 2;
}

.news {
  flex: 0.9;
  padding: 20px 20px 20px 50px;
  max-height: 700px;  

}
.newscards{
  overflow-y: auto;
  max-height: 550px;

}

.intro-text {
  text-align: start;
}

.intro-text h4 {
  font-size: 3.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Poppins';
  text-align: left;
}

.intro-text p {
  font-size: 1.2rem;
  color: #1c3583;
  font-family: 'Poppins';
}

.button-container {
  display: flex;
  margin-top: 20px;
}

.btn--primary{
    background-color: #0097B2;
    border: 1px solid #0097B2;
   
    color: white;
}

.news-date{ /* This is the circle */ 
  border-radius:100%;
  width: 6.375vw;
  height: 6.375vw;
  font-family: 'Poppins';
  border: 0.2vw solid #1c3583;
  text-align: center;
}

.news-date .news-month{
  font-weight: bolder;
  font-family: 'Poppins';
  color: #1c3583;
  padding: 0 0;
  margin-top: 20%;
  margin-bottom: 0;
  line-height: 2.6vh;
  font-size: 1.5vw;
}

.news-date .news-day{
  color: #1c3583;
  margin-top: 0;
  font-weight: lighter;
  font-size: 2vw;
  font-family: 'Poppins';
}

.news-card {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  padding: 25px;
  padding-right: 0;
  margin-right: 0;
  border-radius: 95px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  color: #585858;
  background: white;
  border: 0.5px solid #ccc;
}

.news-content {
  margin-left: 50px;
  font-size: 1.4rem;
  z-index:2;
  max-width:15vw;
  padding-right: 0;
  display: flex;
  
}

.news-content .links{

   text-decoration: none;
   color: #1c3583;
   margin: auto;
   align-items: center;
   font-size: 1.2rem;
   font-family: "Poppins";
   font-weight: bolder;
}


.news h5{
  font-size: 3.8rem;
  font-family: 'Poppins';
}

@media (max-width: 768px) {
  .intro-container {
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .sections-container {
    flex-direction: column; /* Stack sections vertically on mobile */
  }

  .introduction{
    flex: 1; /* Equal distribution of space */
    width: 100%; /* Full width for each section */
    max-height: 100vh; /* Remove max-height */
    border-right: none; /* Remove the border for introduction */
    padding: 10px; /* Reduced padding for smaller screens */
    padding-bottom: 60px;
    overflow-y: scroll;
    font-size: 1rem;
  }
  .news {
    flex: 1; /* Equal distribution of space */
    width: 100%; /* Full width for each section */
    max-height: 50vh; /* Remove max-height */
    border-right: none; /* Remove the border for introduction */
    overflow-y: scroll;
    font-size: 1rem;
    border-top: 1px solid rgb(218,218,218);

  }

  .button-container {
    margin-top: 10px; /* Adjusted margin for button container */
  }

  /* Style adjustments for text */
  .intro-text h4 {
    font-size: 2.4rem; /* Smaller font size for headings */
    text-align: center;
    margin-bottom: 10px;
  }

  .intro-text p {
    font-size: 1rem; /* Smaller font size for paragraphs */
  }

  /* Style adjustments for the "Learn More" button */
  .btn--primary {
    font-size: 1.2rem;
    padding: 10px 20px;
  }

  /* Style adjustments for the "News" section heading */
  .news-date{ /* This is the circle */
  
  border-radius:100%;
  width: 6rem;
  height: 6rem;
  font-family: 'Poppins';
  border: 0.2vw solid black;
  text-align: center;
}

.news-date .news-month{
  font-weight: bolder;
  color: #1c3583;
  padding: 0 0;
  margin-top: 30%;
  margin-bottom: 1vh;
  line-height: 1vh;
  font-size: 1.5rem;
}

.news-date .news-day{
  color: #1c3583;
  margin-top: 0;
  font-weight: lighter;
  font-size: 1.75rem;

}

.news-content {
  margin-left: 30px;
  font-size: 1rem;
  z-index:2;
  padding-right: 0;
  margin-right: 0;
  font-family: "Poppins";
  
}

.news-content .links{

   text-decoration: none;
   color: #1c3583;
   text-align: center;
   font-family: "Poppins";
   font-size: 0.85rem;

}


.news h5{
  font-size:2.4rem;
  text-align: left;
  font-family: 'Poppins';

}

.news-card{
  padding: 10px;
}
}

