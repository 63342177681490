.card-grid {
  /* margin-top: 5.28vh; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  justify-items: center;
  padding: 40px;
}


@media (max-width: 767px) {
  .card-grid {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
  padding: 0px;
  padding-bottom: 20px;
}

}