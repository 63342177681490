


/* Colab Header and Image section */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */


.colab-header{
    height: 100vh;

}


.overlay{
position:absolute;
left:140px;
bottom: 40vh;
background: rgba(255,255,255,.0);
height: 50vh;
width: 49vw;
}

.overlay h1{
    color: #163374;
    font-size: 5rem;
    bottom:0;
    display:flex-end;
    margin-bottom: 0;
    line-height:1.2;
    font-family: 'Gotham Black'
}


 .top-image { 
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.coa-logo{
    position:absolute;
    top:310px;
    left:140px;
    height: 100px;
}

.colab-logo{
    position:absolute;
    
    right:-500px;
    top:0px;
    height: 100px;

}

.ut-seal{
    position:absolute;
    top:310px;
    left:290px;
    height: 100px;
}

.scroll-down-box{
    position:absolute;
    bottom:0vh;
    left:50%;
    transform: translateX(-50%); 
    height: 50px;
    width:100vw;
    background-color:rgba(0,0,0,0.3);
    align-items:center;
 
}
.scroll-down{
    height: 50px;
    position:absolute;
    left:50%;
    transform: translateX(-50%); 
    display:hidden;
}
.button-grid {
    display: flex;
    gap: 10px;
    margin-top: 600px;
    padding: 10px;
    padding-left:120px;
    position:absolute;
    flex-direction:row;
}


.pdf-button {
    display: flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    background-color: #163374;
    color: white;
    text-decoration: none;
    padding: 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family:'Helvetica';
}

.pdf-button:hover {
    background-color: #0056b3;
}

.pdf-button-content {
    font-size: 16px;
    font-weight: bold;
}



/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */

/* Problems to Solve */

.Problems{
    display: flex;
    height: auto;
    flex-direction:column;
    

}
.Problems h1{
    color: #bf5700;
    font-size: 3.4rem;
    margin: 20px auto; /* top 0 horizontally center */
    font-family: 'Gotham Black';
}

.circle-section {
    display: flex;
    flex-direction: row;
    margin: 20px auto;
}


.circle {
  border-radius: 50%;      /* Creates the circular shape */
  width: 300px;            /* Width of the circle */
  height: 300px;           /* Height of the circle */
  display: flex;           /* Enables flexbox for centering content */
  align-items: center;     /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
  margin: 10px;            /* Margin around each circle */
  text-align: center;      /* Aligns text inside the circle */
  padding: 20px;           /* Padding inside the circle */
  background-color: #d0f4ff; /* Background color of the circle */
  font-family:'Poppins';
  font-size: 1.2rem;
  }
.circle:nth-child(1){
    background-color:#C8EEF9;
}

.circle:nth-child(2){
    background-color:#94D0E0;
}

.circle:nth-child(3){
    background-color:#69C4D9;
}

.circle:nth-child(4){
    background-color:#01CDED;
}





/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */

/* Urgency */

.urgency{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction:row;
    justify-content: center;
    font-family: 'Gotham Black';
    margin-top:40px;
    
    
}

.group-pic{
    max-width:min(737px, 50%);
    height:400px;
    object-fit:cover;
    flex:1;
}

.urgency-text{
    padding-left:20px;
    max-width:min(737px, 50%);
    flex:1;

}

.urgency-title{
    font-size:4rem;
    font-family: 'Gotham Black';
}

.urgency-content{
    font-size: 1.3rem;
}


/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */

/* Focus */

.focus{
    height:auto;
    width:auto;
    flex-direction:row;
    display:flex;
    align-items:flex-end;
    background-color: #fff;
    justify-content: center;
    
}

.focus-box{
    display: flex;
    flex-direction: column;
    align-items:center;
    width:min(737px, 50%);
    padding: 20px;
    background-color:#187a89;
    margin-bottom: auto;
    color: white;
    padding-bottom:40px;
    
}



.focus-box:nth-child(1) {
    background-color:#b45e18;

}

.focus-title{
    font-size:3rem;
    font-family: 'Gotham Black';
}



.focus-content{
    list-style-type: disc;
    padding-left: 10px;
    font-size: 1.3rem;
    

}



li {
  margin-bottom: 10px; /* Space between list items */
}


/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */

/* Ongoing */

.colab-ongoing{
    height: auto;
    display:flex;
    flex-direction:row;
    padding:20px;
    padding-top:80px;
    margin:0 auto;
    overflow-x:scroll;
    justify-content:center;
    font-family: 'Gotham Black';

}

.colab-ongoing-card { 
    flex-grow:0;
    flex-shrink:0;
    
    
    width:240px ; 
    height:400px;
    border:1px solid #f0f0f0;
    border-radius:10%;
    padding:20px;
    text-align:center;
    background-color:#C8EEF9;
    margin: 0 20px;
    


}

.colab-section{
    transform:translate(0,-20%);

}
.ongoing-title{
    font-size:1.7em;
    line-height: 1.2;
    font-family: 'Gotham Black';
}

.colab-ongoing-card p{
    text-align: left;
    font-size:1rem;
}

.colab-ongoing-card img {
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    max-width: 100%; /* Ensures the image does not overflow the card width */
    border-radius: 50%; /* Optional: if you want a circular image */
}


/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */

/* Structure */


.colab-structure{
    height:auto;
    flex-direction: column;
    display:flex;
    padding: 20px;

}

.structure-title{
    font-size: 3rem;
    text-align: center;
    font-family: 'Gotham Black';
}

.colab-structure-section{
    flex-direction:row;
    display:flex;
    align-items: center;
    max-width:100vw;
}

.colab-structure-section-1{
    align-items: center;
    margin: 0 auto;
    max-width:50%;
        object-fit:contain;

}

.colab-structure-section-1-img{
    align-items: center;
    margin: 0 auto;
    max-width:100%;
}

.colab-structure-section-2 {
    display: flex;
    flex-direction: column;
    margin:0 auto;
    max-width: 50%;
}

.section-2-content{
    flex-direction: column;

}

.headshot {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.headshot-text{
    margin-top: 5px;
    font-size: 1rem;
    color: #333; /* Adjust the color as needed */
}



.headshots-title{
    font-size:2rem;
    text-align: center;
    margin-bottom: 0;
}
.headshot-row {
    display: flex;
    justify-content: center;
    max-width:100%;
}

.headshot-row img {
    border-radius: 50%; /* Circular images */
    margin: 20px;
    width: 150px; /* Adjust as needed */
    height: 150px; /* Adjust as needed */
    object-fit: cover;

}

.colab-structure-section-2-title{
    font-size:1.5rem;
}



.top-row {
    margin-bottom: 20px;
}



/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */

/* Next Steps */


.colab-progress{
    height:auto;
    padding: 20px;
    background-color:#C8EEF9;;

}

.colab-progress-title{
    font-size: 3rem;
    font-family: 'Gotham Black';

}

.next-steps{
    padding-left:25px;
}
.next-steps-list{
    list-style-type: disc;
    padding-left: 10px;
    font-size: 1.3rem;

}

.timeline{
    margin-top: 40px;
    background-color:#69C4D9;
    width:100vw;
    margin-left:-20px;
    padding-left: 20px;
    padding-bottom:60px;
}

.timeline-container{
}
@media screen and (max-width: 768px) {

    /* Header and Image Section */
    .colab-header {
        height: 100vh; /* Reduced height */
    }

     .top-image { 
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

    .overlay {
        left: 10px;
        top:15vh;
        width: 95vw; /* Adjusted for smaller width */
    }

    .overlay h1 {
        font-size: 3rem; /* Smaller font size */
    }

    .coa-logo  {
        top: 30vh;
        left: 10px; /* Align to the left */
        height: 70px; /* Smaller logos */
    }

    .ut-seal{
        top: 30vh;
        left: 90px; /* Align to the left */
        height: 70px;

    }

    /* Problems to Solve Section */
    .Problems {
        margin-top:40px;
        flex-direction: column; /* Stack elements vertically */
    }

    .Problems h1{
        text-align:center;
        font-size:2.2rem;
        margin-top:40px;
}


    .circle-section {
        flex-direction: column;
        margin: 10px auto;
    }

    .circle {
        border-radius: 4%;      /* Creates the circular shape */
        width: 90vw;
        height: auto; /* Adjust height automatically */
        margin: 5px; /* Smaller margin */
        font-size:1rem;
    }

    /* Urgency Section */
    .urgency {
        flex-direction: column; /* Stack elements vertically */
        margin-bottom:50px;
    }

    .group-pic {
        width: 100%; /* Full width */
        height: auto; /* Adjust height automatically */
    }

    .urgency-text {
        padding-left: 10px;
    }

    .urgency-title {
        font-size: 2.5rem; /* Smaller font size */
    }

    .urgency-content {
        font-size: 1rem; /* Smaller font size */
    }

    /* Focus Section */
    .focus {
        flex-direction: column; /* Stack elements vertically */
    }

    .focus-box {
        width: 100%; /* Full width */
    }

    .focus-title{
        font-size:2.2rem;
        margin-bottom:20px;
    }
    .focus-content{
        font-size:1.1rem;
    }

    /* Ongoing Section */
    .colab-ongoing {
        flex-direction: column; /* Stack elements vertically */
        background-image:none !important;

    }



    .colab-ongoing-card {
        width: 90vw; /* Adjusted for smaller width */
        height:auto;
        margin: 3vh 0;
        
    }



    .colab-section p{
        margin-top: 20px;
        
    }
    
    .colab-ongoing-card img{
        
    }

    /* Structure Section */
    .colab-structure-section {
        flex-direction: column; /* Stack elements vertically */
    }

    .colab-structure-section-1 {
        width: 90vw; /* Adjusted for smaller width */
        padding-bottom: 30px;
        border-bottom: 1px solid grey;
        max-width:100%;
    }

    .headshot-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: auto auto; /* Two rows */
        justify-items: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
    }

    .headshot-row img {
        width: 90px; /* Adjust size as needed */
        height: 90px; /* Adjust size as needed */
    }

    /* Styling for the single headshot that appears on the second row */
    .headshot-row .single {
        grid-column: 1 / -1; /* Stretch across all columns */
        justify-self: center;
    }

    /* Progress Section */
    .colab-progress {
        height: auto; /* Adjust height automatically */
    }
    .colab-progress-title{
        font-size:2.2rem;
    }
    .next-steps-list{
        font-size:1.1rem;
    }

    .timeline {
        margin-top: 20px;
    }
}

@media screen and (max-width:1278px) and (min-width:770px){
    .ut-seal, .coa-logo{
        top:350px;

    }

    .Problems {
        margin-top:60px;
        flex-direction: column; /* Stack elements vertically */
    }

    .Problems h1{
        text-align:center;
        font-size:2.2rem;
}


    .circle-section {
        flex-direction: column;
        margin: 10px auto;
    }

    .circle {
        border-radius: 4%;      /* Creates the circular shape */
        width: 90vw;
        height: auto; /* Adjust height automatically */
        margin: 5px; /* Smaller margin */
        font-size:1rem;
    }

     .colab-structure-section {
        flex-direction: column; /* Stack elements vertically */
    }

    .colab-structure-section-1 {
        width: 90vw; /* Adjusted for smaller width */
        padding-bottom: 30px;
        border-bottom: 1px solid grey;
        max-width:100%;
        align-items:center;
        justify-content: center;
        display:flex;
    }

     .colab-structure-section-1-img{
        max-width:50%;
        margin: 0 auto;

     }

     .colab-structure-section-2 {

    max-width: 100%;
    flex-direction:row;
}

    .headshot-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: auto auto; /* Two rows */
        justify-items: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
    }

    .headshot-row img {
        width: 90px; /* Adjust size as needed */
        height: 90px; /* Adjust size as needed */
    }

    /* Styling for the single headshot that appears on the second row */
    .headshot-row .single {
        grid-column: 1 / -1; /* Stretch across all columns */
        justify-self: center;
    }

    


}