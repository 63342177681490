.feature-project {
    background-color: #282828;
    color: white;
    display: flex;
    padding: 40px;
    align-items: center;
    min-height: 500px;
}

.feature-heading {
    margin-right: 40px;
    flex: 0 0 30%;
    font-size: 3.8rem;
    font-family: 'Poppins';
}

.featuredcards-container {
    display: flex;
    gap: 20px;
    flex: 0 0 70%;
    justify-content: center;
}

.featuredcard {
    background-color: #F3F8FA;
    position: relative;
    width: 100%;
    max-width: 250px;
    height: auto; 
    border-radius: 20px;
    transition: transform 0.3s ease; 
    text-decoration: none;
    color: inherit;
}

.featuredcard:hover {
    transform: scale(1.05);
}

.blue-rectangle {
    background-color: #BFF8FA;
    height: 50px;
    border-radius: 20px 20px 0 0;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon-wrapper i {
    color: black;
    font-size: 18px;
    transform: rotate(-45deg);
}

.arrow-icon {
    font-size: 18px;
}

.featuredcard-title {
    margin-top: 60px;
    padding: 0 25px;
    color: #1c3583;
    font-family: 'Poppins';
}

.featuredcard-content {
    margin-top: 10px;
    padding: 0 25px 20px;
    color: #1c3583;
    font-family: 'Poppins';
}

@media (max-width: 600px) {
    .feature-project {
        flex-direction: column;
        padding: 20px;
    }

    .feature-heading {
        font-size: 2.4rem;
        margin-right: 0;
        margin-bottom: 40px;
        text-align: center;
    }

    .featuredcards-container {
        width: 100%;
        align-items: center;
        gap: 40px;
        flex-direction: column;
    }
}
