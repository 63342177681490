/* ParisRDP.css */

.parisbutton {
    background-color: #FFFFFF;
    border: 1px solid rgb(209,213,219);
    border-radius: .5rem;
    box-sizing: border-box;
    color: #111827;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: .75rem 1rem;
    text-align: center;
    text-decoration: none #D1D5DB solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 10px;
  }
  
  .parisbutton:hover {
    background-color: rgb(249,250,251);
  }

  
  .parisbutton:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }


.graph-title{
  padding: 30px 70px;
}

.thermscale-box{
  padding: 20px 70px;
}

.graph-box{
  display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f0f0f0;
    padding: 70px 0;
    width: calc(100% - 140px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0 70px;
}

  .graphs-container {
    display: flex;
    justify-content: space-between; /* Space items evenly */
    padding: 0 70px;
  }


/* Individual image wrapper */
.image-wrapper {
  position: relative;
  width: 330px;
  margin-bottom: 20px;
  overflow: hidden; 
}

/* Style the images */
.forecast-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.lb-image {
  width: 80vw !important;
  height: 80vh !important;
  object-fit: contain;
  margin: 0 auto;
}


.lb-overlay {
  background-color: rgba(0, 0, 0, 0.9) !important; /* Darken the overlay */
}


.lb-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  max-height: 100vh;
}

/* Hover effect on image */
.image-wrapper:hover .forecast-image {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Overlay for the image (appears on hover) */
.forecastimage-overlay {
  position: absolute;
  padding: 15px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6); /* Light white background */
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden; /* Ensure overlay doesn't block the image until hover */
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

/* Show the overlay when the image is hovered */
.image-wrapper:hover .forecastimage-overlay {
  opacity: 1; /* Show the overlay */
  visibility: visible;
}

/* Style the text inside the overlay */
.forecastimage-overlay p {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 15px;
}

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

  .graphs-container h2{
    text-align: center;
  }
  
  .graphs-container img {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    width: 330px;
    min-height: 300px;
    max-height: 300px;
    margin-bottom: 20px;
    object-fit: contain;
    display: block;
}

  .forecast-title {
    padding: 20px 70px;
}

.forecast-title h1 {
  margin: 20px 0 10px;
}

.forecast-title h2 {
  margin: 20px 0 10px;
}

.forecast-title p {
  margin-bottom: 20px;
}
  
  .parisbutton:focus-visible {
    box-shadow: none;
  }

  .thermalscape-vid{
    padding: 20px 70px;
  }

  .thermalscape-vid video{
    width:100%;
    padding-top:20px;
  }

  .thermalscape-img{
    padding: 20px 70px;
  }

  .thermalscape-img img{
    width:100%;
    padding-top:20px;
  }

  .no-scroll {
    overflow: hidden;
  }

  
  @media screen and (max-width:480px) {
    .parisbutton {
      height: 20px;
      line-height: 0rem;
      padding: 5px;
      font-size: 8px;
    }
  }
  
  /* Create two equal columns that floats next to each other */
  .outercolumn1 {
    float: left;
    width: 40%;
    padding: 10px;
  }
  
  .outercolumn1 p {
    margin-left: 20px;
    text-align: left;
    color: black;
  }
  
  .outercolumn2 {
    float: left;
    width: 60%;
    padding: 10px;
  }
  
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  }
  
  .column img {
    margin-top: 10px;
    width: 330px;
    height: 270px;
  }

  .border-line {
    width: auto;
    height: 1px;
    background-color: #282828;
    margin: 30px 70px;
  }
  
  @media screen and (max-width:480px) {
    .column img {
      width: 100%;
      height: auto;
    }
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Styles for the full-screen background image with foreground content */
  .parisheader {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: flex-start;
    padding: 20px 50px 0;
  }
  
  .paris-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align items to the right */
    max-width: 60%; /* Limit width for better layout */
    margin-bottom: 20px;
}

.title-logo {
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
  
  .title-logo img {
    max-width: 15%;
  }
  
  .title-logo h1 {
    float: right;
  }

  .back-button {
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
  }
  
  .back-button:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
  }
  
  .back-button:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
  }
  
  .back-button:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
  }
  
  .back-button:focus {
    outline: 1px transparent;
  }
  
  .back-button:before {
    display: none;
  }
  
  .back-button:-webkit-details-marker {
    display: none;
  }
  
  .RDPtext-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: steelblue; /* Translucent background for content */
    color: white;/* Black text color */
    text-align: center;
    padding: 20px;
  }

  @media screen and (max-width:480px) {
    .RDPtext-box h1 {
      font-size: 16px;
      text-align: center;
    }
  
    .RDPtext-box p {
      font-size: 12px;
      text-align: center; /* Center align text */
    }
  }
  
  .heading-text {
    z-index: 3;
    margin-top: 30px;
    padding: 20px;
    border-radius: 10px; 
    text-align: left;
    color: #FFFFFF;
    background-color: rgba(40, 40, 40, 0.8);
    display: flex;
    flex-direction: column;
    max-width: 55%;
}

  
  .RDPtext-box a {
    text-decoration: underline;
    font-size: 1.4em;
    color: rgb(14, 255, 235);
  }
  
  .RDPtext-box a:hover {
    color: rgb(64, 0, 255);
  }
  
  .header2-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .header-container {
    display: flex;
    justify-content: space-between; /* Space between left and right */
    align-items: flex-start; /* Align items at the top */
    padding: 20px;
    gap: 50px;
    width: 100%; 
}

.title-content {
  display: flex;
  flex-direction: column;
}

  
  .header2-text {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: black;
    max-width: 40%; /* Set the maximum width of the content box */
    background-color: white; /* Set the background color of the content box with translucency */
    padding: 20px; /* Add padding inside the content box */
    border-radius: 10px; /* Optional: Add rounded corners to the box */
    margin-bottom: 20px; /* Space between content and footer */
    align-content: center;
    height: 50%;
    width: 30%;
  }
  
  @media screen and (max-width:480px) {
    .header2-text {
      width: 70%;
      font-size: 10px;
      height: auto;
    }
  }
  
  .olympicsiteimg {
    width: 100%;
    height: 700px;  }
    
    @media screen and (max-width:480px) {
      .olympicsiteimg {
        width: 100%;
    height: 350px; 
      }
    }

  .footer-text {
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.75);
    color: black;
    text-align: left;
    padding: 0.5%;
  }
  
  .footer-text a {
    color: #00aaff; /* Optional: Color for links */
    text-decoration: none;
  }
  
  .footer-text a:hover {
    text-decoration: underline;
  }

  .footer-content {
    z-index: 3;
    color: black;
    margin-top: 20px;
    padding: 10px 50px; 
    margin-bottom: 20px;
    align-self: center;
    width: calc(100% - 140px);
}

  /* .footer-content p {
    margin: 10px 0 0;
    font-size: 1em;
    color: black;
} */

.button-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.social-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 16px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.underline-text {
  text-decoration: underline;
}
  .highlighttext {
    color:rgb(142, 3, 3);
  }
  
  .image-container {
    /* text-align: center; */
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    float: right;
  }
  
  .meteoganimage-container {
    padding: 20px 70px;;
    margin: 20px 0;
  }
  
  .meteoganimage-container:first-of-type {
    border-bottom: 1px solid grey;
  }
  
  .meteoganimage-container img {
    width: 80vw;
    height: auto;
  }

  .calendargrid {
    position: relative;
    z-index: 3;
    background-color: rgba(40, 40, 40, 0.8);
    padding: 20px;
    border-radius: 10px; 
    margin-top: 20px;
    align-self: flex-end;
    max-width: 400px;
   }

   .calendargrid h1 {
    font-size: 2rem;
    margin: 0;
}

.highlighted-date {
  background-color: #0097B2 !important;
  color: white !important;
  border-radius: 50%;
  font-weight: bold;
}

  .parisheader h1 {
    margin: 0;
    font-size: 3rem;
    color: rgb(255, 255, 255);
  }


  .parisheader h2 {
    margin: 0;
    font-size: 24px;
    color: rgb(255, 255, 255);
  }
  
  .parisheader p {
    margin: 10px 0 0;
    font-size: 16px; /* Adjust the font size as needed */
    color: rgb(255, 255, 255);
  }
  
  .footer-content p {
    margin: 10px 0 0;
    font-size: 1em; /* Adjust the font size as needed */
    color: black;
  }
  
  .olympiccards-container {
    width: 100%; /* Full width */
    display: flex;
    flex-direction: row;
    margin-top: 0vh; /* Adjust the margin-top to eliminate the whitespace */
    z-index: 3;
  }

  .olympiccard{
    display: flex;
    width: 100%;
    height: 500px; /* Adjust the height as needed */
  }

  @media screen and (max-width:480px) {
    .olympiccard {
      width: 100%;
      height: 350px;
    }
  }

  .olympiccard.flipped {
  }
  
  .olympiccard-image {
    flex: 1;
    display: flex;
  }

  .olympiccardgrid-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }

  @media screen and (max-width:480px) {
    .oolympiccard-image img {
      width: 100%;
    height: 100%;
    object-position: right;
    }
  }

  .olympiccard-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the full area */
  }
  
  .olympiccard-content1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 200, 255, 0.7); /* Translucent background for content */
    color: black; /* Black text color */
  }
  
  .olympiccard-content1 h2 {
    margin: 10px;
    font-size: 1.5em; /* Adjust the font size as needed */
  }
  
  .olympiccard-content1 p {
    margin: 10px 0 0;
    font-size: 1em; /* Adjust the font size as needed */
  }
  
  .bottom-text {
    width: 100%; /* Full width */
    background-color: white; /* White background */
    color: black; /* Black text */
    padding: 20px; /* Add padding inside the bottom text div */
    text-align: left; /* Center align text */
    z-index: 3;
    font-size: 1em;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .parisheader {
      padding: 20px;
  }

  .header-container {
      flex-direction: column;
      width: 100%;
      align-items: center;
      
  }

  .heading-text {
      max-width: 90%;
      margin: 10px auto;
  }

  .calendargrid {
      width: 90%;
      margin: 20px auto;
  }
    .title-box, .content-box {
      max-width: 90%;
      padding: 15px;
    }
  
    .parisheader h1 {
      font-size: 1.8em;
    }
  
    .parisheader p {
      font-size: 1em;
    }
  
    .footer-content {
      width: calc(100% - 40px);
      margin: 20px auto;
      padding: 15px;
  }
  
    .footer-content p {
      font-size: 0.9em;
    }
  
    .bottom-text {
      padding: 15px;
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .parisheader {
      height: auto;
      align-items: center; /* Center align content */
    }
  
    .title-box, .content-box {
      max-width: 100%;
      padding: 10px;
      margin: 10px 0; /* Add some margin between the boxes */
      position: relative;
    }
  
    .parisheader h1 {
      font-size: 24px;
      text-align: left;
    }

    .parisheader h2 {
      font-size: 14px;
      text-align: left;
    }
  
    .parisheader p {
      font-size: 12px;
      text-align: left; /* Center align text */
    }

    .title-logo{
      margin-bottom: 20px;
      margin-left: 20px;
    }
  
    .footer-content p {
      font-size: 0.8em;
    }
  
    .bottom-text {
      padding: 10px;
      font-size: 0.8em;
    }

    .button-row {
      flex-direction: column;
  }

  .social-button {
      font-size: 14px;
      padding: 10px 0;
  }

  .footer-content {
      width: calc(100% - 30px);
      padding: 10px;
  }

  .footer-text {
      font-size: 12px;
  }

  .graphs-container img {
    width: 300px;
}

.graphs-container {
  padding: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.border-line {
  margin: auto;
}

.image-wrapper {
  align-self: center;
  width: auto;
}
  }
  