.navbar {
  background: linear-gradient(90deg, rgb(68, 68, 68) 0%, rgb(63, 59, 59) 100%);
  height: 100px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: "Poppins";
  transition: top 0.3s;
}

.joinus_button{
  background-color: #FFFFFF;
  border: 1px solid #0097b2;
  border-radius: 25px;
  box-sizing: border-box;
  color: #0097b2;
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.joinus_button:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

.joinus_button:active {
  background-color: #F7F7F7;
  border-color: #000000;
  transform: scale(.96);
}

.joinus_button:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 100vw;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  padding-right: 20px;
  padding-left: 10px;
}

.texus-header {
  border-left: 1px solid white;
  height: 70px;
  width: 300px;
  font-size: 1rem;
}

.texus-header h5 {
  margin-top: 5%;
  text-align: center;
  font-size: calc(2.5vw);
  height: 50px;
  font-family: "Helvetica-Bold";
  color: white;
  text-shadow: 2px 2px 0px #0097B2;
}

.navbar-logo3 {
  display: none;
}

.navbar-logo2 {
  color: #fff;
  justify-self: right;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: right;
  font-family: "Poppins";
  padding-right: 10px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
  font-family: "Poppins";
}

.nav-menu {
  display: flex;
  align-items: center;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: flex-end;
  margin-left: auto;
}

.nav-item {
  height: -100px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: "Poppins";
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar.hidden {
  top: -90px; /* Adjust the height of your navbar */
  background: rgb(67, 67, 67);
}

.navbar.opaque {
  background: linear-gradient(90deg, rgb(68, 68, 68) 0%, rgb(63, 59, 59) 100%);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #444; /* Background color */
  list-style: none;
  padding: 0;

  box-shadow: 0 2px 4px rgba(78, 78, 78, 0.1); /* Box shadow */
  background: linear-gradient(90deg, rgb(68, 68, 68) 0%, rgb(63, 59, 59) 100%);
}

.dropdown-content li {
  border-bottom: 0.1px solid black;
}

.inner-dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #444;
  list-style: none;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(90deg, rgb(68, 68, 68) 0%, rgb(63, 59, 59) 100%);
  /* background: radial-gradient(#e66465, #9198e5); */
}

.inner-dropdown-content li {
  border-bottom: 0.001px solid black;
}

.inner-dropdown-content li:last-child {
  border-bottom: none;
}

/* Adjust the font size and padding for the inner dropdown content text */
.inner-dropdown-content .nav-links {
  font-size: 14px;
  padding: 8px 16px;
  min-width: 100px;
}

.dropdown-content > .nav-item:hover .inner-dropdown-content {
  display: block;
}

.nav-item:hover .dropdown-content {
  display: block;
}

.logo-image2{
  width:80px;
  height: 60px;
  margin-left: 30px;
}

.footer-logo {
  border-bottom: 1px solid white;
  display: flex;
}

.footer-logo h5{
  margin-top: 5%;
  text-align: center;
  font-size: calc(2.5vw);
  height: 50px;
  font-family: "Helvetica-Bold";
  color: white;
  text-shadow: 2px 2px 0px #0097B2;
}


@media screen and (max-width: 960px) {
  .navbar{
    justify-content: center;
  }
  
  .NavbarItems {
    position: relative;
  }

  .texus-header {
    display: none;
  }

  .nav-menu {
    display: block;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow-y: scroll;
  }

  .nav-menu.active {
    background: #ff6200;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    height: 11vh;
    display: block;
    border: 0.1px solid rgb(255, 255, 255);
  }

  .nav-links:hover {
    color: #fff;
    border: 5px solid white;
  }

  .navbar-logo {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .navbar-logo3 {
    position: absolute;
    transform: translate(0%, 0%);
    margin: auto auto;
    font-size: 3rem;
    font-family: "Helvetica-Bold";
    color: white;
    text-shadow: 2px 2px 0px #0097B2;
    text-decoration: none;
    display: block;
  }

  .navbar-logo2 {
    display: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 0%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .dropdown-content {
    display: none;
    position: relative;
    background-color: #444;
    list-style: none;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #fd9346;
    top: 20%; /* Position below the parent menu item */
    left: 0; /* Reset left position */
    width: 100%; /* Take full width of the container */
  }
  .inner-dropdown-content {
    display: none;
    position: relative;
    background-color: #0097B2;
    list-style: none;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #fdb777;
    text-align: center;
    font-size: 2rem;
    align-items: center;

    top: 20%; /* Position below the parent menu item */
    left: 0; /* Reset left position */
    width: 100%; /* Take full width of the container */
  }

  .nav-item.dropdown:hover .dropdown-content {
    display: block;
  }

  .nav-item.inner-dropdown:hover .inner-dropdown-content {
    display: block;
    top: 20%; /* Position at the top of the parent menu item */
    left: 0; /* Position to the right of the parent menu item */
    width: 100%;
    transition: 250ms;
  }

  .inner-dropdown-content .nav-links {
    font-size: 1.1rem;
    text-align: center;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center;

    min-width: 100px;
    height: 100px;
    margin: auto;
  }
}

@media screen and (min-width:1700px){
  .texus-header h5 {
    font-size:2.5rem;
  }
}