

.hero-container {

  width: 100vw;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  background-color: black; /* Set the background color */
  position: relative;
  
}



.hero-slider {
  width: 100vw;
  height: auto; /* Adjust as needed */
  overflow: hidden;
  background-color:black;
  
}
.video-title {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  color: white; /* Set the color of the title */
  font-size: 120px;; /* Set the font size of the title */
  font-family: 'Archive';
  text-shadow: 5px 5px 0px #0097B2; /* Add a text shadow for better visibility */
  z-index: 2; /* Ensure the title is above the video */
}

.video-subtitle {
  position: absolute;
  top: 16%; /* Adjust the value to position the title vertically */
  left: 50%;
  transform: translateX(-50%);
  color: white; /* Set the color of the title */
  font-size: 40px;; /* Set the font size of the title */
  font-family: 'Helvetica-Bold';
  text-shadow: 3px 3px 0px #0097B2; /* Add a text shadow for better visibility */
  z-index: 2; /* Ensure the title is above the video */
}
.text-three {
  position: absolute;
  top:20%; /* Adjust the value to position the title vertically */
  left: 50%;
  transform: translateX(-50%);
  color: white; /* Set the color of the title */
  font-size: 40px;; /* Set the font size of the title */
  font-family: 'Helvetica-Bold';
  text-shadow: 3px 3px 3px #000000;
  
  z-index: 2; /* Ensure the title is above the video */
}

.ut-logo {
  position:absolute;
  bottom: 24vw; /* Adjust the value to position the title vertically */
  transform: translateX(-50%);
  max-width: 300px; /* Adjust the maximum width as needed */
}


@media  screen and (max-width: 1333px) and (min-width: 760px) {
  .video-subtitle {

  bottom: 80%; /* Adjust the value to position the title vertically */
}

.video-title {
  bottom: 50%;
}


}

@media screen and (max-width: 760px) {
  .hero-container {
    height:calc(100vh - 300px);
    text-align: center;
  }

  

  .video-title {
    font-size: 3rem; /* Adjust font size for mobile */
    top: 25%;
   text-shadow: 2px 2px 0px #0097B2; /* Add a text shadow for better visibility */
    z-index: 2; /* Ensure the title is above the video */
    max-width: 200px; /* Adjust the max-width as needed */
    word-wrap: break-word;
}
  

  .video-subtitle {
    font-size: 1.5rem; /* Adjust font size for mobile */
    top: 21%;
    text-shadow: 2px 2px 0px #0097B2; 
  }

  .text-three {
    font-size: 18px; /* Adjust font size for mobile */
    bottom: 35%;
  }

  .ut-logo {
    max-width: 300px; /* Adjust the maximum width for mobile */
    margin-top: 20px; /* Add margin to separate logo from text */
    bottom: 5%;
  }

  .hero-slider {
    height:calc(100vh - 100px); /* Resize the video to half the screen width */
    max-width: 100vw; /* Remove the max-width constraint */
    width: auto;
    
     /* Maintain aspect ratio */
  }
}