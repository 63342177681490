.cards {
  border: 0.3px solid #ccc;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
  width: 440px;
  display: grid;
  flex-direction: column;
}

.cards:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-out;
}

.card-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
  display: flex; /* Add flex display */
  flex-direction: column; /* Set flex direction to column */
}

.cards .card-title {
  font-size: 24px;
  font-family: 'Poppins';
  text-align: left;
  margin: 20px 0 20px;
  color: #1c3583;
  height: 3rem;
}

.cards .card-picture {
  width: 100%;
  height: 266px;
  border-radius: 18px;
}

.cards .card-content {
  font-size: 18px;
  font-family: 'Helvetica Now', sans-serif;
  color: #1a1a1a;
  text-align: left;
  width: 100%;
  margin: 5px 0 0; /* Adjust margin for the picture */
  padding: 0; /* Adjust padding */
}

.cards .btn {
  margin-top: 0px;
  width: 100%;
  height: 50px;
}

.btn--primary {
  background-color: #0097B2;
  font-family: 'Poppins';
}

@media (max-width: 767px) {

  .cards .card-content {
    font-size: 14px;
  }

  .cards .card-title {
    font-size: 18px;
    margin: 10px 0 10px;
  }
  .cards {
    font-size: 1rem;
    font-family: 'Poppins';
    color: #585858;
    text-align: left;
    width: 90vw;
    padding: 0; /* Adjust padding */
    margin: auto auto;
  }
  
  .card-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit text color */
    display: flex; /* Add flex display */
    flex-direction: column; /* Set flex direction to column */
    padding: 12px;
  }

  
  .card-content {
    font-size: 1rem;
    font-family: 'Poppins';
    color: #585858;
    text-align: left;
    width: 100%;
    margin: 0; /* Adjust margin */
    padding: 0; /* Adjust padding */
  }
}