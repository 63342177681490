/* Styles for the full-screen background image with foreground content */
.header1 {
    position: relative;
    width: 100%;
    height: 100vh; /* Full screen height */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align content to the left */
    color: white;
    text-align: left; /* Left align text */
    padding-left: 20px; /* Add padding to the left side */
    z-index: 2;
}

.header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the full area */
    z-index: 1;
}

.title-box {
    position: absolute;
    z-index: 3;
    max-width: 90%; /* Set the maximum width of the title box */
    background-color: rgba(0, 0, 0, 0.7); /* Set the background color of the title box with translucency */
    padding: 20px; /* Add padding inside the title box */
    border-radius: 10px; /* Optional: Add rounded corners to the box */
    margin-bottom: 20px; /* Space between title and content */
    top: 10px;
}

.content-box {
    position: relative;
    z-index: 3;
    max-width: 40%; /* Set the maximum width of the content box */
    background-color: rgba(0, 0, 0, 0.7); /* Set the background color of the content box with translucency */
    padding: 20px; /* Add padding inside the content box */
    border-radius: 10px; /* Optional: Add rounded corners to the box */
    margin-bottom: 20px; /* Space between content and footer */
}

.content-box a{
    text-decoration: underline;
    font-size:1.4em;  
    color:rgb(14, 255, 235);
    
}

.content-box a:hover{
    
    color:rgb(64, 0, 255);
    
}

.footer-content {
    position: absolute;
    z-index: 3;
    width: 100%; /* Full width */
    background-color: white; /* White background */
    color: black; /* Black text */
    padding: 30px; /* Add padding inside the footer content box */
    border-radius: 0; /* No rounded corners */
    text-align: left; /* Left align text */
    bottom: 0px;
}

.footer-content a {
    color: #00aaff; /* Optional: Color for links */
    text-decoration: none;
}

.footer-content a:hover {
    text-decoration: underline;
}

.image-container-1 {
    text-align: center;
    margin: 20px 0;
    border-bottom: 1px solid grey;
    
  }

  .image-container-1:first-of-type {
    border-bottom: 1px solid grey;
}


  
  .image-container-1 img {
    width: 80vw;
    height: auto;
  }

.header1 h1 {
    margin: 0;
    font-size: 2.5em; /* Adjust the font size as needed */
    color:rgb(255, 255, 255);
}

.header1 p {
    margin: 10px 0 0;
    font-size: 1.2em; /* Adjust the font size as needed */
    color:rgb(255, 255, 255);
}

.footer-content p {
    margin: 10px 0 0;
    font-size: 1em; /* Adjust the font size as needed */
    color:black;
}

.cards-container {
    width: 100%; /* Full width */
    display: flex;
    flex-direction: column;
    margin-top: 0vh; /* Adjust the margin-top to eliminate the whitespace */
    z-index: 3;
}

.card {
    display: flex;
    width: 100%;
    height: 600px; /* Adjust the height as needed */
}

.card.flipped {
}

.card-image {
    flex: 1;
    display: flex;
}

.card-image img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the image covers the full area */
}

.card-content1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 200, 255, 0.7); /* Translucent background for content */
    color: black; /* Black text color */
}

.card-content1 h2 {
    margin: 10px;
    font-size: 1.5em; /* Adjust the font size as needed */
}

.card-content1 p {
    margin: 10px 0 0;
    font-size: 1em; /* Adjust the font size as needed */
}
.bottom-text {
    width: 100%; /* Full width */
    background-color: white; /* White background */
    color: black; /* Black text */
    padding: 20px; /* Add padding inside the bottom text div */
    text-align: left; /* Center align text */
    z-index: 3;
    font-size:1em;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .header1 {
        padding-left: 10px;
        height: auto; /* Adjust height for smaller screens */
    }
    
    .title-box, .content-box {
        max-width: 90%;
        padding: 15px;
    }

    .header1 h1 {
        font-size: 1.8em;
    }

    .header1 p {
        font-size: 1em;
    }

    .footer-content {
        padding: 20px;
    }

    .footer-content p {
        font-size: 0.9em;
    }

    .bottom-text {
        padding: 15px;
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .header1 {
        padding-left: 0;
        align-items: center; /* Center align content */
    }

    .header-background {
        display: none; /* Hide background image */
    }

    .title-box, .content-box {
        max-width: 100%;
        padding: 10px;
        margin: 10px 0; /* Add some margin between the boxes */
        position:relative;
    }

    .header1 h1 {
        font-size: 1.5em;
        text-align: center; /* Center align text */
    }

    .header1 p {
        font-size: 0.9em;
        text-align: center; /* Center align text */
    }

    .footer-content {
        padding: 15px;
        position:relative;

    }

    .footer-content p {
        font-size: 0.8em;
    }

    .bottom-text {
        padding: 10px;
        font-size: 0.8em;
    }
}