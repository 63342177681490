.content-section {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  display: flex;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.content-image {
  flex: 1;
  padding: 0px;
  margin-top: 0px;
  max-width: 30%; /* Adjust the width as needed */
}

.content-image img {
  max-width: 100%; /* Make sure the image is responsive */
  height: auto; /* Maintain the aspect ratio */
}

.content-text {
  flex: 2;
  padding-left: 2%;
  text-align: justify;
  font-size: 1.3rem;
}

.content-section h2 {
  flex-basis: 100%; /* Ensure the title takes full width */
  text-align: justify;
; /* Adjust spacing */
  margin-bottom: 20px;
  order: 1;
}

@media (max-width: 960px) {

  .content-section {
  margin: 5px;
  padding: 20px;
  padding-bottom : 0px;
  border: 1px solid #ccc;
  display: flex;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  margin: -20px;
  overflow: hidden;
}

.content-image {
  flex: 1;
  padding: 0px;
  margin-top: 0px;
  max-width: 100%; /* Adjust the width as needed */
  transform: translate(0,-20%);
  overflow: hidden;
  position: relative;
}

.image-container{
  position: relative;
  overflow: hidden;
}

.image-container img {
  max-width: 100%; /* Make sure the image is responsive */
  height: auto; /* Maintain the aspect ratio */

}

.content-text {
  flex: 2;
  padding-left: 2%;
  text-align: left;
  font-size: 1rem;
  font-family: 'Poppins';
}

.content-section h2 {
  flex-basis: 100%; /* Ensure the title takes full width */
  text-align: left;
; /* Adjust spacing */
  margin-bottom: 20px;
  order: 1;
  font-family: 'Poppins';
}

}