/* In src/components/pages/GeoHealth.module.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.geoMain {
  max-width: 100vw;
  margin: 20px auto;
  padding: 30px;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  
}

.headerNavContainer {
  display: flex;
  justify-content: space-between; /* Centers the items horizontally */
  align-items: center; /* Centers the items vertically */
  gap: 20px; 
}

 .header1 {
  color: #2a57a5; /* A deep blue shade */
  margin-bottom: 25px;
  font-size: 8em;
  font-weight: 700;
  font-family: 'Poppins';
  overflow: hidden;
}


.geoHeader {
  flex: 0 0 auto; /* Adjust the basis as needed, this won't allow it to grow */
  max-width: 50vw;
  width: 100%;
}

.geoMain p {
  color: #4a4a4a; /* A darker shade for better readability */
  line-height: 1.7;
  margin-bottom: 18px;
  font-size: 1.4em;
  text-align: left;
}

.email {
  color: #e85a4f; /* A vibrant red shade */
  text-decoration: none;
}

.email:hover {
  text-decoration: underline;
}

.navMenu {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 3 columns, each taking up an equal fraction of the available space */
  gap: 10px;
  max-width: 50vw;
  width: 100%;
  
}

.navMenu a {
  display: block;
  background-color: #5d70b7e1; /* Light background color for boxes */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  text-decoration: none;
  color: rgb(255, 255, 255);
  
}

.navMenu a:hover {
  text-decoration: underline;
  background-color: #9fd0f9
}

html {
  scroll-behavior: smooth;
}

.section {
  padding: 10px;
  margin-top: 50px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);

}

.section h2 {
  color: #2a57a5; /* Matching the color scheme of the page */
  margin-bottom: 15px;
  font-size: 2em;
  text-align: center;
}

.professorContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.professorImage {
  width: 300px; /* Adjust as needed */
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.professorText {
  flex-grow: 1;
  max-width: 60%; /* Adjust as needed */
}

.courseModules {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.courseModules h2 {
  color: #333;
  margin-bottom: 15px;
}

/* Styles for the table */
.modulesTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.modulesTable th,
.modulesTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.modulesTable th {
  background-color: #4CAF50;
  color: white;
}

.modulesTable td {
  background-color: #f2f2f2;
}

/* Responsive Styles */

@media (max-width: 768px) {
    .geoHeader{
        padding: 10px;
    }

    .navMenu {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: center;
  }
  .headerNavContainer {
    flex-direction: column;
  }

.navMenu a {
  margin: 4px 20px;
  text-decoration: none;
  color: #0656ab;
  font-size: 1rem;
}
  .container {
    padding: 20px;
    max-width: 90%;
  }

  .header1 {
    font-size: 1.8em;
  }

  p {
    font-size: 1em;
  }

  .modulesTable {
    display: block;
    overflow-x: auto;
  }
}

.introduction {
  background-color: #f9f9f9; /* Or any light shade for contrast */
  padding: 20px;
  margin-top: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left; /* Align text to the left for better readability in longer texts */
}

.introduction h2 {
  color: #2a57a5; /* Matching the color scheme of the page */
  margin-bottom: 15px;
  font-size: 2em;
  text-align: center;
}
  .header2{
    font-size: 2rem;
    color: #2a57a5;

  }

  .header3{
    font-size: 2rem;
    color: #007bff;
  }

.introduction p {
  color: #4a4a4a;
  line-height: 1.6;
  margin-bottom: 15px;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .headerNavContainer {
    flex-direction: column;
    align-items: center; /* Centers the items in the column */
  }

  .geoHeader,
  .navMenu {
    max-width: 100%; /* Sets max-width to full width for smaller screens */
    flex: 0 0 auto; /* Prevents flex items from growing */
  }

  .navMenu {
    grid-template-columns: repeat(2, 1fr); /* Adjust number of columns for smaller screen */
  }

  .navMenu a {
    padding: 10px; /* Adjust padding for smaller screen */
    padding-right: 20px;
    padding-left: 20px;
  }

  h1 {
    font-size: 2.5em; /* Adjust font size for smaller screens */
  }

  p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .navMenu {
    grid-template-columns: 1fr; /* Single column layout for very small screens */
  }

  /* Further adjustments for elements on very small screens */
  h1 {
    font-size: 2em;
  }
  
  .professorContent {
    flex-direction: column;
    align-items: center;
  }
  
  .professorImage {
    max-width: 100%;
    margin-bottom: 15px;
  }
  
  .professorText {
    max-width: 100%;
  }
}
