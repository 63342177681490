    body {
        margin: 0;
        padding: 0;
    }

    .news-container {
        width: 100vw;
        background-color: #fbf7f7;
        display: flex;
        flex-wrap: wrap;
        height:500px;
    }

    .newsimage-container {
        height: 100%;
        display: grid;
        width: 40vw;
    }

    .newsimage-container img{
        align-self: center;
        justify-self: center;
        max-width: 570px;
        max-height: 500px;
        object-fit: cover;
        border-radius:20px;
    }

    .news-article {
        width: 100vw;
        height: auto;

    }

    .article-content {
        display: flex;
        height: 100%;
        align-items: center;
        
    }

    .image-on-left {
        flex-direction: row;
        background-color: #ffffff;
        object-fit: contain;
        
    }


    .image-on-right {
        flex-direction: row-reverse;
        background-color: #ffffff;
    }



    .article-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: left;
        padding: 24px;
        color: #1c3583;
        width: 50%;
        margin: 20px 20px 50px 20px;
    }

    .subtext {
        font-size: 1.2rem;
        
    }
    .header{
        height: 200px;
    }
    .header h1{
        margin-bottom: -1vh;
        z-index: 30;
        top: 0vh;
        margin-top: -20vh;
        margin-left: 40px;
        color: #ffffff;
        text-shadow: 5px 5px 0px #0097B2; /* Add a text shadow for better visibility */
        text-align: start;
        padding: 0px 0;
        font-size: 5rem;
        font-family: 'Poppins';
        position: relative;
    }


    .title-text {
        text-decoration: none;
        color: #1c3583;
        font-weight: bold;
        font-family: 'Poppins', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 3rem;
    }

    @media (max-width: 767px) {
        .article-content {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 20px;
            border: 1px solid rgb(218, 218, 218);
        }

        
        .newsimage-container {
            width: 100%;
            height: 50%;
            display: grid;
            max-width: 100%;
            overflow: hidden;
        }
        .newsimage-container img{
            max-width: 400px;
            max-height: 450px;
        }

        .article-text {
            width: 100%;
            margin-bottom: 20px;
        }

        .title-text {
            font-size: 2rem;
        }

        .news-container {
            /* padding-top: 28%; */
        }

        .header h1{
            font-size: 2rem;
        }
    }