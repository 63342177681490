ul.contentlist{
  list-style-type: square;
}
.join-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Adjust as needed */
  align-items: left;
  height: 100vh;
  position: relative;
  padding: 20px; /* Add padding to the container */
}

.join-header{
  top: 20px;
  color: #cc5500;
  text-align: left;
  font-size: 5rem;
  margin: 0; /* Reset margin to avoid unexpected spacing */
  align-content: center;
}

#titleroundedbox {
  border-radius: 2px 20px 20px 2px;
  background: #1c3583;
  padding: 20px;
  height: 15vh;  
  width: 40vw;
}

.img-container {
  width: 25vw;
  height: 30vw; /* Adjust to make it a perfect circle */
  overflow: hidden;
  border-radius: 20px;
  margin-right: 20px; /* Add right margin to create space between image and text */
}

.img-container img.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  margin: auto;
  text-align: left;
  color: #1c3583;
  font-size: 1.4rem;
}

.content {
  text-align: left;
  padding: 20px; /* Add padding to the content */
}

.join-header {
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 3.8rem;
}

.join-subheader {
  color: #1c3583;
  font-size: 1.4rem;
  margin-bottom: 10px; /* Add bottom margin to the subheader */
}

.contact-link {
  color: #0073e6;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {

  #titleroundedbox {
    border-radius: 2px 20px 20px 2px;
    background: #1c3583;
    padding: 20px; 
    height: 9vh;  
    width: 70vw;
  }

  .join-header {
    font-family: "Poppins", sans-serif;
    color: white;
    font-size: 1.5rem;
  }

  .join-container {
    flex-direction: column-reverse;
    height: auto;
  }

  .img-container {
    width: 45vw;
    height: auto;
}

}
